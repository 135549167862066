import React, { useState, useContext, useEffect } from "react";
import Basket from "../Basket";
import { AuthContext } from "../../AuthContext";
import { useBasket } from "../../basketContext";
import { motion, AnimatePresence } from "framer-motion";
import {
  ShoppingBagIcon,
  UserCircleIcon 
} from "@heroicons/react/24/outline";
import {
  
  SparklesIcon,
} from "@heroicons/react/24/solid";
import Button from "../Button";
import { useNavigate, useLocation } from "react-router-dom";
import Divider from "../Divider/Divider";
import ProgressBar from "../ProgressBar/ProgressBar";

const navigation = [{ name: "Shop Prints", href: "/prints", current: false }];

const Header = () => {
  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userCreateCredits, setUserCreateCredits] = useState(0);
  const { signOut, session, createCredits } = useContext(AuthContext);
  const { basketQuantity } = useBasket();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleBasket = () => {
    setIsBasketOpen(!isBasketOpen);
    document.body.classList.toggle("overflow-hidden", !isBasketOpen);
  };

  const toggleUserMenu = () => {
    if (session && session.user.id) {
      setIsUserMenuOpen(!isUserMenuOpen);
    } else {
      navigate("/login");
    }
  };

  const handleSignOut = async () => {
    toggleUserMenu();
    await signOut();
    navigate("/login");
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => setUserCreateCredits(createCredits), [createCredits]);

  if (location.pathname === "/generate-print") {
    return null;
  }

  return (
    <>
    <header className="flex justify-between items-center py-4 px-6 bg-white border-bottom border-slate-200 border">
      <div className="flex md:items-center md:justify-center sm:items-stretch sm:justify-start ">
        <a
          href="/"
          className="flex flex-shrink-0 items-center text-xl font-semibold"
        >
          <img
            src="https://nluobsmaflcaxqiawcvk.supabase.co/storage/v1/object/public/static/homepage/create-prints-logo.png"
            width="200"            
            alt="Create Prints AI Logo"  
            className="hidden md:block"          
          />
          <img
            src="https://nluobsmaflcaxqiawcvk.supabase.co/storage/v1/object/public/static/homepage/create-prints-logo-small.png"
            width="28"            
            alt="Create Prints AI Logo"  
            className="md:hidden"          
          />
          <span className="sr-only">Create Prints</span>
        </a>
        <div className="ml-6 sm:ml-12 md:block">
          <div className="flex space-x-6">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={classNames(
                  item.current
                    ? "bg-slate-950 text-white"
                    : "text-slate-750 hover:underline-offset-8 hover:underline hover:slate-750",
                  "transition-all ease-in-out rounded-md px-3 py-2 font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-self-end">
        <div className="flex md:mr-6">
          <div
            className="text-slate-900 flex flex-row cursor-pointer"
            onClick={toggleBasket}
          >
            {basketQuantity > 0 && <div>{basketQuantity}</div>}
            <ShoppingBagIcon className="h-6 w-6 mx-2" />
          </div>
          <div className="relative">
            {session?.user.identities[0].identity_data.picture ? (
              <img
                onClick={toggleUserMenu}
                className="mx-2 w-6 rounded-full cursor-pointer"
                src={session?.user.identities[0].identity_data.avatar_url}
              />
            ) : (
              <UserCircleIcon
                className="h-6 w-6 mx-2 cursor-pointer"
                onClick={toggleUserMenu}
              />
            )}
            <AnimatePresence>
              {isUserMenuOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10, transition: "easeInOut" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, transition: "easeInOut", y: -10 }}
                  transition={{ duration: 0.1 }}
                  className="absolute z-50 top-8 right-0 bg-white p-2 shadow-lg rounded-md text-slate-900 text-sm"
                >
                  <div className="w-56 p-2">
                    <div className="text-lg">
                      {session?.user.identities[0].identity_data.full_name}
                    </div>
                    <div className="mt-2 mb-4">
                      <ProgressBar
                        current={userCreateCredits}
                        label="Remaining Create Credits"
                        total={5}
                        className="my-2 bg-stone-300"
                      />
                      <p className="text-slate-400 text-xs">
                        Create credits are restored after a purchase or 30 days.
                      </p>
                    </div>
                    <Divider />
                  </div>

                  <ul>
                    <li className="py-2 px-2.5 hover:bg-slate-100 rounded-sm">
                      <a href="/account" className="block">Account</a>
                    </li>
                    <li className="py-2 px-2.5 hover:bg-slate-100 rounded-sm">
                      <button onClick={handleSignOut} className="w-full text-left">Sign out</button>
                    </li>
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        <Button
          className="text-base font-medium hidden whitespace-nowrap md:block"
          url={session?.user.id ? `/generate-print` : `/login`}
        ><SparklesIcon className="h-4 w-4 mr-2" />
          Create Your Art Print
        </Button>        

        <AnimatePresence>
          {isBasketOpen && (
            <>
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: "easeInOut",
                  minWidth: "400px",
                  width: "25%",
                }}
                exit={{ opacity: 0, x: 200, transition: "easeInOut" }}
                className="absolute top-0 right-0 bottom-0 bg-white p-4 z-50 shadow-md"
              >
                <Basket handleClose={toggleBasket} />
              </motion.div>
              <div
                className="absolute top-0 left-0 right-0 bottom-0 bg-slate-950/60 z-40 overflow-hidden"
                onClick={toggleBasket}
              />
            </>
          )}
        </AnimatePresence>
      </div>
    </header>
    <Button className="fixed md:hidden bottom-6 right-6 !rounded-full cursor-pointer !p-5 drop-shadow-xl z-10" url="/generate-print">
    <SparklesIcon className="h-4 w-4" />
  </Button>
  </>
  );
};

export default Header;
