import { useCallback, useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext.js";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { Box, Image, Layout, List, Sliders } from "lucide-react"; // Import icons

import Button from "../../components/Button";
import Cards from "../../components/Cards";
import InputKeywords from "../../components/InputKeywords/InputKeywords";
import InputColors from "../../components/InputColors/InputColors";
import TextArea from "../../components/TextArea";
import Spinner from "../../components/Spinner/Spinner";
import getImageDescription from "../../utility/getImageDescription";
import postGenerationJob from "../../utility/postGenerationJob";
import ARImageViewer from "../../components/ARImageViewer"; // Import ARImageViewer

const orientations = [
  {
    id: 3,
    created_at: "2023-12-02T12:33:30.778762+00:00",
    friendly_name: "Landscape",
    value: "1792x1024",
    // "image_path": "landscape.png"
  },
  {
    id: 2,
    created_at: "2023-12-02T12:33:15.336182+00:00",
    friendly_name: "Portrait",
    value: "1024x1792",
    // "image_path": "portrait.png"
  },
  {
    id: 1,
    created_at: "2023-12-02T12:32:48.846237+00:00",
    friendly_name: "Square",
    value: "1024x1024",
    // "image_path": "square.png"
  },
];

const promptData = [
  {
    id: 1,
    created_at: "2023-12-02T12:46:55.785707+00:00",
    friendly_name: "Text",
    value: "describe",
  },
  {
    id: 3,
    created_at: "2024-03-05T15:35:34.46406+00:00",
    friendly_name: "Interior",
    value: "interiorPhoto",
  },
  {
    id: 2,
    created_at: "2023-12-02T12:47:26.526828+00:00",
    friendly_name: "Print",
    value: "printPhoto",
  },
];

const GeneratePrintV2 = () => {
  const [promptType, setPromptType] = useState({
    id: 1,
    friendly_name: "Text Description",
    value: "describe",
  });

  const [userSelection, setUserSelection] = useState({
    orientation: orientations[1],
    style: null,
    prompt: null,
    promptType: null,
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [isDescribing, setIsDescribing] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [generatedImage, setGeneratedImage] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAR, setShowAR] = useState(false); // State to manage AR view toggle
  const [activeTab, setActiveTab] = useState("method"); // Set default tab to 'method'

  const { session, createCredits } = useContext(AuthContext);
  const { slug } = useParams();
  const navigate = useNavigate();

  const containerRef = useRef(null); // Ref to get the container height

  const scale = useMotionValue(1);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const handleWheel = (event) => {
    const newScale = event.deltaY < 0 ? scale.get() * 1.1 : scale.get() / 1.1;
    if (newScale >= 1) {
      scale.set(newScale);
    }
  };

  useEffect(() => console.log(userSelection), [userSelection]);

  useEffect(() => {
    setFirstLoad(createCredits === null);
  }, [createCredits]);

  useEffect(() => {
    createCredits <= 0 ? setBlocking(true) : setBlocking(false);
  }, [createCredits]);

  useEffect(() => {
    if (userSelection.prompt) {
      setTextareaValue(userSelection.prompt);
    }
  }, [userSelection.prompt]);

  const handleOrientationSelection = useCallback((data) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      orientation: data,
    }));
  }, []);

  const handlePromptTypeSelection = (item) => {
    setPromptType(item);
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      promptType: item,
    }));
  };
  useEffect(() => {
    console.log(selectedFile);
  }, [selectedFile]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsDescribing(true);
    setErrorMessage("");
    try {
      const description = await getImageDescription(
        file,
        promptType.value,
        session.access_token
      );
      setUserSelection((currentSelection) => ({
        ...currentSelection,
        prompt: description,
      }));
    } catch (error) {
      console.error("Error getting image description:", error);
      if (error.response && error.response.status === 429) {
        setErrorMessage("Too many requests, please try again later.");
      } else {
        setErrorMessage("Error getting image description. Please try again.");
      }
    } finally {
      setIsDescribing(false);
    }
};

  const handlePromptChange = useCallback((e) => {
    const newValue = e.target.value;
    setTextareaValue(newValue);
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      prompt: newValue,
    }));
  }, []);

  const handlePromptKeywordsChange = useCallback((data) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      keywords: data,
    }));
  }, []);

  const handleColorsChange = useCallback((colors) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      colors,
    }));
  });

  const handleSubmitJob = async () => {
    setLoading(true);
    setErrorMessage("");    
    const data = {
      prompt_size: userSelection?.orientation?.id
        ? userSelection.orientation.id
        : 2,
      original_prompt: textareaValue,
      user_id: session.user.id,
      style: userSelection?.style?.id ? userSelection.style.id : null,
      keywords: userSelection.keywords,
      prompt_type: promptType.id,
      seed_id: slug ? slug : null,
      // color_category: userSelection?.colorScheme?.colorCategory,
      // color_psychology_impact_level:
      //   userSelection?.colorScheme?.colorPsychology?.impactLevel,
      // color_psychology_primary_trait:
      //   userSelection?.colorScheme?.colorPsychology?.primaryTrait,
      // recommended_room: userSelection?.colorScheme?.recommendedRoom,
      // color_palette: userSelection?.colorScheme?.colorPalette,
    };
    console.log(data);
    try {
      const response = await postGenerationJob(
        data,
        userSelection,
        session.user.id,
        session.access_token
      );
      if (response && response.data && response.data.imageData) {
        console.log(response);
        setGeneratedImageUrl(response.data.imageData.mergedImageUrls[0]);
        setGeneratedImage(true);
        // Store the image title and UUID
        setUserSelection(prev => ({
          ...prev,
          imageTitle: response.data.imageData.title,
          imageUUID: response.data.jobDetails.id
        }));
      }
      console.log(response.data.imageData);
    } catch (error) {
      console.error("Error in handleSubmitJob: ", error);
      setErrorMessage("Error generating print. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBuyPrint = () => {
    console.log(userSelection);
    if (userSelection.imageTitle && userSelection.imageUUID) {
      const slug = userSelection.imageTitle.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
      navigate(`/print/${slug}/${userSelection.imageUUID}`);
    } else {
      console.error("Image title or UUID is missing");
    }
  };

  // Add this useEffect to log generatedImages when it changes
  useEffect(() => {
    console.log("Generated Images:", generatedImageUrl);
  }, [generatedImageUrl]);

  return (
    <div className="flex flex-1 flex-col">
      {firstLoad ? (
        <div className="flex flex-1 items-center justify-center bg-white">
          <div className="text-center flex flex-col justify-center items-center">
            <Spinner size={48} speed={0.5} /> Loading
          </div>
        </div>
      ) : (
        <div className="flex flex-col-reverse flex-1 overflow-hidden md:flex-row bg-white">
          <motion.aside
            className={`w-screen md:w-1/3 md:max-w-md p-6 overflow-y-auto${
              loading ? " opacity-25" : ""
            }`}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
          >
            {blocking && (
              <div className="mb-6 p-4 bg-red-50 rounded-md">
                <h2 className="text-lg font-bold mb-2 text-slate-900">
                  You're out of credits
                </h2>
                <p className="text-sm text-slate-600">
                  To continue to create prints you need more credits. You can
                  earn credits by making a purchase or waiting 30 days.
                </p>
              </div>
            )}
            <div className="md:hidden flex justify-around mb-6">
              <button
                className={`flex flex-col items-center ${
                  activeTab === "method" ? "text-blue-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("method")}
              >
                <List className="w-6 h-6" />
                <span>Method</span>
              </button>
              <button
                className={`flex flex-col items-center ${
                  activeTab === "orientation"
                    ? "text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("orientation")}
              >
                <Layout className="w-6 h-6" />
                <span>Orientation</span>
              </button>
              <button
                className={`flex flex-col items-center ${
                  activeTab === "advanced"
                    ? "text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("advanced")}
              >
                <Sliders className="w-6 h-6" />
                <span>Advanced</span>
              </button>
            </div>
            <div className="md:block">
              <div className={`mb-6 ${activeTab === "method" ? "block" : "hidden"} md:block`}>
                <label>Method</label>
                <Cards
                  onChange={handlePromptTypeSelection}
                  defaultSelection={userSelection?.promptType?.id || promptType.id}
                  data={promptData}
                  showIcon={false}
                  className="text-left"
                  disabled={loading || blocking}
                />
                {(promptType.value === "describe" ||
                  userSelection.prompt) && (
                  <div className="my-6">
                    <label>Description</label>
                    <TextArea
                      onChange={handlePromptChange}
                      value={textareaValue}
                      name="prompt"
                      placeholder="Describe your print..."
                      disabled={isDescribing || loading || blocking}
                    />
                  </div>
                )}
                {promptType.value !== "describe" && (
                  <div className="my-6">
                    <label htmlFor="imageUpload" className="sr-only">
                      Upload or Take a Photo:
                    </label>
                    <input
                      type="file"
                      id="imageUpload"
                      accept="capture,image/*"
                      onChange={handleFileChange}
                      className="p-2 bg-white border-2 border-dashed border-slate-950 rounded-md w-full"
                      disabled={loading || blocking}
                    />
                    {isDescribing && (
                      <div className="mt-2 text-sm flex py-2">
                        <Spinner size={24} speed={0.5} className="mr-1" />{" "}
                        Generating complimentary artwork description...
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={`mb-6 ${activeTab === "orientation" ? "block" : "hidden"} md:block`}>
                <label>Orientation</label>
                <Cards
                  data={orientations}
                  defaultSelection={
                    userSelection?.orientation?.id
                      ? userSelection.orientation.id
                      : null
                  }
                  onChange={handleOrientationSelection}
                  showIcon={false}
                  disabled={loading || blocking}
                />
              </div>
              <div className={`mb-6 ${activeTab === "advanced" ? "block" : "hidden"} md:block`}>
                <div className="flex justify-between align-bottom">
                  <label className="block mb-2">Keywords</label>
                  <span className="text-sm text-gray-400">Optional</span>
                </div>
                <InputKeywords
                  keywords={userSelection.keywords}
                  onChange={handlePromptKeywordsChange}
                  className="w-full"
                  disabled={loading || blocking}
                />
                <div className="flex justify-between align-bottom mt-6">
                  <label className="block mb-2">Colours</label>
                  <span className="text-sm text-gray-400">Optional</span>
                </div>
                <InputColors
                  imageUrl={selectedFile}
                  paletteAmount={5}
                  onChange={handleColorsChange}
                  disabled={loading || blocking}
                />
              </div>
            </div>
            <Button
              className="w-full mt-6"
              disabled={isDescribing || loading || blocking}
              onClick={handleSubmitJob}
            >
              {blocking ? "Out of Credits" : "Create Print"}
            </Button>
          </motion.aside>
          <main
            ref={containerRef}
            className={`flex flex-col flex-1 justify-center items-center bg-slate-100 w-full relative overflow-hidden ${
              !showAR ? "p-24" : ""
            }`}
          >
            {loading ? (
              <>
                <Spinner size={48} speed={0.5} /> Creating Print...
              </>
            ) : (
              generatedImageUrl && (
                <>
                  {showAR ? (
                    <div className="w-full">
                      <ARImageViewer
                        imageUrl={generatedImageUrl}
                        height={
                          containerRef.current
                            ? containerRef.current.clientHeight
                            : "100vh"
                        }
                      />
                    </div>
                  ) : (
                    <motion.div
                      className="shadow-md w-1/2"
                      style={{ scale, x, y }}
                      onWheel={handleWheel}
                      drag={scale.get() > 1}
                      dragConstraints={{
                        left: -100,
                        right: 100,
                        top: -100,
                        bottom: 100,
                      }}
                      dragElastic={0.2}
                    >
                      <motion.img
                        src={generatedImageUrl}
                        alt="Generated image"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      />
                    </motion.div>
                  )}
                  <div className="absolute bottom-6 left-6 z-100">
                    <Button
                      className="shadow-sm bg-white !text-slate-900 !hover:bg-slate-100"
                      onClick={handleBuyPrint}
                    >
                      Buy Print
                    </Button>
                  </div>
                  <Button
                    className="absolute top-6 right-6 z-100 shadow-sm bg-white !text-slate-900 !hover:bg-slate-100"
                    onClick={() => setShowAR(!showAR)}
                  >
                    <div className="flex items-center gap-2">
                      {showAR ? (
                        <>
                          <Image /> Show Image
                        </>
                      ) : (
                        <>
                          <Box /> Show AR
                        </>
                      )}
                    </div>
                  </Button>
                </>
              )
            )}
          </main>
        </div>
      )}
    </div>
  );
};

export default GeneratePrintV2;
