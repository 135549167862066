import axios from "axios";

const getImageDescription = async (selectedFile, promptType, accessToken) => {
  const formData = new FormData();
  formData.append("promptImage", selectedFile);
  formData.append("promptType", promptType);  

  const baseUrl = process.env.REACT_APP_API_URL;

  try {
    const response = await axios.post(
      `${baseUrl}/get-image-description`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": accessToken
        }
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error describing image:", error);
    throw error;
  }
};

export default getImageDescription;
