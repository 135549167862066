import nearestColor from 'nearest-color';

const colors = {
  // Blues
  'navy': '#000080',
  'dark blue': '#00008B',
  'royal blue': '#4169E1',
  'cornflower blue': '#6495ED',
  'steel blue': '#4682B4',
  'sky blue': '#87CEEB',
  'azure': '#007FFF',
  'cerulean': '#2A52BE',
  'powder blue': '#B0E0E6',
  'baby blue': '#89CFF0',
  'ice blue': '#A5F2F3',
  
  // Teals & Aquas
  'turquoise': '#40E0D0',
  'light turquoise': '#7FFFD4',
  'dark turquoise': '#00CED1',
  'teal': '#008080',
  'aqua': '#00FFFF',
  'aquamarine': '#7FFFD4',
  'seafoam': '#98FF98',
  'cyan': '#00FFFF',
  
  // Greens
  'emerald': '#50C878',
  'forest green': '#228B22',
  'dark green': '#006400',
  'sage': '#BCB88A',
  'olive': '#808000',
  'lime': '#00FF00',
  'mint': '#3EB489',
  'hunter green': '#355E3B',
  'moss green': '#8A9A5B',
  'fern green': '#4F7942',
  'jungle green': '#29AB87',
  'pine green': '#01796F',
  'sea green': '#2E8B57',
  'kelly green': '#4CBB17',
  
  // Reds
  'red': '#FF0000',
  'dark red': '#8B0000',
  'crimson': '#DC143C',
  'burgundy': '#800020',
  'maroon': '#800000',
  'wine': '#722F37',
  'scarlet': '#FF2400',
  'ruby': '#E0115F',
  'cherry': '#DE3163',
  
  // Pinks
  'pink': '#FFC0CB',
  'hot pink': '#FF69B4',
  'deep pink': '#FF1493',
  'rose': '#FF007F',
  'magenta': '#FF00FF',
  'fuchsia': '#FF00FF',
  'salmon': '#FA8072',
  'coral': '#FF7F50',
  'peach': '#FFE5B4',
  
  // Purples
  'purple': '#800080',
  'royal purple': '#7851A9',
  'violet': '#8B00FF',
  'lavender': '#E6E6FA',
  'plum': '#DDA0DD',
  'mauve': '#E0B0FF',
  'indigo': '#4B0082',
  'amethyst': '#9966CC',
  'periwinkle': '#CCCCFF',
  'orchid': '#DA70D6',
  
  // Yellows & Golds
  'yellow': '#FFFF00',
  'golden': '#FFD700',
  'amber': '#FFBF00',
  'mustard': '#FFDB58',
  'butter': '#FFF4C1',
  'canary': '#FFFF99',
  'lemon': '#FFF700',
  'cream': '#FFFDD0',
  
  // Oranges
  'orange': '#FFA500',
  'tangerine': '#F28500',
  'marigold': '#EAA221',
  'peach': '#FFE5B4',
  'apricot': '#FBCEB1',
  'rust': '#B7410E',
  'burnt orange': '#CC5500',
  
  // Browns
  'brown': '#964B00',
  'chocolate': '#7B3F00',
  'coffee': '#6F4E37',
  'mocha': '#967969',
  'tan': '#D2B48C',
  'beige': '#F5F5DC',
  'khaki': '#C3B091',
  'bronze': '#CD7F32',
  'caramel': '#C68E17',
  'sepia': '#704214',
  'mahogany': '#C04000',
  'sienna': '#882D17',
  'saddle brown': '#8B4513',
  
  // Greys
  'black': '#000000',
  'charcoal': '#36454F',
  'dark grey': '#404040',
  'grey': '#808080',
  'silver': '#C0C0C0',
  'light grey': '#D3D3D3',
  'platinum': '#E5E4E2',
  
  // Whites
  'white': '#FFFFFF',
  'ivory': '#FFFFF0',
  'cream': '#FFFDD0',
  'pearl': '#F0EAD6',
  'off-white': '#FAF9F6',
  'eggshell': '#F0EAD6',
  'snow': '#FFFAFA'
};

const getColorName = (rgb) => {
  // Convert RGB array to hex
  const toHex = (n) => {
    const hex = Math.round(n).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  
  const hex = `#${toHex(rgb[0])}${toHex(rgb[1])}${toHex(rgb[2])}`.toUpperCase();
  
  // Get the nearest color
  const nearest = nearestColor.from(colors);
  return nearest(hex).name;
};

export { getColorName }; 