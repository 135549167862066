import React, { useEffect, useRef, useState } from "react";

const ARImageViewer = ({ imageUrl, width = "100%", height = "100vh" }) => {
  const modelViewerRef = useRef(null);
  const [modelLoaded, setModelLoaded] = useState(false);

  useEffect(() => {
    const loadModelViewer = () => {
      if (typeof customElements.get("model-viewer") === "undefined") {
        const script = document.createElement("script");
        script.type = "module";
        script.src =
          "https://unpkg.com/@google/model-viewer@latest/dist/model-viewer.js";
        document.body.appendChild(script);
      }
    };

    loadModelViewer();
  }, []);

  useEffect(() => {
    const modelViewer = modelViewerRef.current;
    const handleModelLoad = () => {
      console.log("Model has loaded");
      setModelLoaded(true);
      const materials = modelViewerRef.current.model.materials;
      console.log(
        "Available materials:",
        materials.map((mat) => mat.name)
      );
    };

    const handleError = (event) => {
      console.error("Model Viewer Error:", event);
    };

    if (modelViewer) {
      modelViewer.addEventListener("load", handleModelLoad);
      modelViewer.addEventListener("error", handleError);
    }

    return () => {
      if (modelViewer) {
        modelViewer.removeEventListener("load", handleModelLoad);
        modelViewer.removeEventListener("error", handleError);
      }
    };
  }, []);

  useEffect(() => {
    if (modelLoaded && imageUrl) {
      swapTexture();
    }
  }, [modelLoaded, imageUrl]);

  const swapTexture = async () => {
    const modelViewer = modelViewerRef.current;
    if (modelViewer && imageUrl) {
      try {
        const texture = await modelViewer.createTexture(imageUrl);

        const material = modelViewer.model.materials[1];
        console.log(material);
        console.log(material.pbrMetallicRoughness);
        //material.pbrMetallicRoughness.setBaseColorFactor("#666666");
        //material.pbrMetallicRoughness["normalTexture"].setTexture(texture);
        material.pbrMetallicRoughness.baseColorTexture.setTexture(texture);
      } catch (error) {
        console.error("Error creating texture from URL:", error);
      }
    }
  };

  return (
    <model-viewer
      ref={modelViewerRef}
      src={process.env.PUBLIC_URL + "/picture-frame.glb"}
      alt="A 3D model of some wall art"
      ar
      ar-scale="fixed"
      ar-placement="wall"
      xr-environment
      camera-controls
      touch-action="pan-y"
      style={{ width, height }}
    ></model-viewer>
  );
};

export default ARImageViewer;
