import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Tab } from "@headlessui/react";
import Cards from "./components/Cards";
import { fetchData } from "./utility/fetchData";
import { useBasket } from "./basketContext";
import { AuthContext } from "./AuthContext";
import formatCurrency from "./utility/formatCurrency";
import Button from "./components/Button";
import { AmendGeneratedImage } from "./components/AmendGeneratedImage.js";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ARImageViewer from "./components/ARImageViewer";
import { getColorName } from "./utility/colorNaming";
import {
  Home, // Living Room
  Sofa, // Family Room
  Bed, // Bedroom
  LampDesk, // Office
  UtensilsCrossed, // Kitchen
  BookOpen, // Study
  Users, // Dining Room
  Sparkles, // Hallway
  Heart, // Nursery
} from "lucide-react";

const roomIcons = {
  "Living Room": Home,
  "Family Room": Sofa,
  Bedroom: Bed,
  "Home Office": LampDesk,
  Kitchen: UtensilsCrossed,
  Study: BookOpen,
  "Dining Room": Users,
  Hallway: Sparkles,
  Nursery: Heart,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GeneratedPrint = () => {
  const { session } = useContext(AuthContext);
  const { slug, id } = useParams();
  const { addToBasket } = useBasket();
  const navigate = useNavigate();
  const [printData, setPrintData] = useState(null);
  const [printFormats, setPrintFormats] = useState([]);
  const [printSizes, setPrintSizes] = useState([]);
  const [printExtras, setPrintExtras] = useState([]);
  const [printFrames, setPrintFrames] = useState([]);
  const [error, setError] = useState(false);
  const [price, setPrice] = useState(0);
  const [variantDetails, setVariantDetails] = useState(null);
  const [lastNonDigitalSize, setLastNonDigitalSize] = useState(null);
  const [addingToBasket, setAddingToBasket] = useState(false);
  const [ownedByUser, setOwnedByUser] = useState(false);
  const [showAmendOverlay, setShowAmendOverlay] = useState(false);
  const [showAR, setShowAR] = useState(false);

  const [userSelection, setUserSelection] = useState({
    format: 2,
    formatTitle: "Print Only",
    size: 4,
    sizeTitle: "A4",
    extras: null,
  });

  const fetchVariantDetails = useCallback(async () => {
    if (!userSelection.format) {
      return;
    }

    try {
      let whereClause = {
        product_id: "990f90ef-ec50-4bf2-8971-85ee065801f3", // Assuming a constant product ID, replace with actual logic if needed
        format_option_id: userSelection.format,
      };

      // Include size and extras in the where clause only if the format is not 'digital'
      if (userSelection.format !== "digital") {
        whereClause = {
          ...whereClause,
          ...(userSelection.size && { size_option_id: userSelection.size }),
          ...(userSelection.extras && {
            extra_option_id: userSelection.extras,
          }),
        };
      }

      const variantData = await fetchData(
        "product_variants",
        "*, products (id, friendly_name)",
        whereClause
      );

      if (variantData.length > 0) {
        setVariantDetails(variantData[0]);
      } else {
        console.error("No variant found for the selected options");
      }
    } catch (error) {
      console.error("Error fetching variant:", error);
    }
  }, [userSelection]);

  useEffect(() => {
    fetchVariantDetails();
  }, [userSelection, fetchVariantDetails]);

  useEffect(() => {
    if (printData && session && session?.user?.id === printData.user_id) {
      setOwnedByUser(true);
    }
  }, [printData]);

  const fetchPrintData = useCallback(async () => {
    try {
      const data = await fetchData(
        "generation_jobs",
        `
          id, 
          title, 
          original_prompt, 
          revised_prompt, 
          image_paths, 
          user_id, 
          keywords, 
          color_category, 
          color_psychology_impact_level, 
          color_psychology_primary_trait, 
          recommended_room, 
          color_palette, 
          description, 
          dominant_color, 
          vibrant_color,
          prompt_size,
          prompt_sizes (
            id,
            value
          )
        `,
        { id: id }
      );
      if (data.length > 0) {
        setPrintData(data[0]);
        console.log("Print data with size:", data[0]); // Debug log
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching generation jobs: ", error);
      setError(true);
    }
  }, [id]);

  const fetchPrintOptions = useCallback(async () => {
    try {
      const optionsData = await fetchData("product_options", "*");

      // Filter options by their type
      const printFormatsData = optionsData.filter(
        (option) => option.type === "Format"
      );
      const printSizesData = optionsData.filter(
        (option) => option.type === "Size"
      );
      const printExtrasData = optionsData.filter(
        (option) => option.type === "Extras"
      );
      const printFramesData = optionsData.filter(
        (option) => option.type === "Frame"
      );

      // Filter sizes based on original image orientation
      const filteredPrintSizes = printSizesData.filter(size => {
        if (!printData?.prompt_sizes?.value) return true; // Show all if no original size

        // Get dimensions from the original image
        const [originalWidth, originalHeight] = printData.prompt_sizes.value.split('x').map(Number);
        const originalAspectRatio = originalWidth / originalHeight;

        // Get dimensions from the print size option
        const [printWidth, printHeight] = size.value.split('x').map(Number);
        const printAspectRatio = printWidth / printHeight;

        // Determine orientation type
        const isOriginalLandscape = originalAspectRatio > 1;
        const isOriginalPortrait = originalAspectRatio < 1;
        const isOriginalSquare = originalAspectRatio === 1;

        const isPrintLandscape = printAspectRatio > 1;
        const isPrintPortrait = printAspectRatio < 1;
        const isPrintSquare = printAspectRatio === 1;

        // Match orientations
        if (isOriginalLandscape) return isPrintLandscape;
        if (isOriginalPortrait) return isPrintPortrait;
        if (isOriginalSquare) return isPrintSquare;

        return true; // Fallback
      });

      setPrintFormats(printFormatsData);
      setPrintSizes(filteredPrintSizes);
      setPrintExtras(printExtrasData);
      setPrintFrames(printFramesData);

      const formatInfo = printFormatsData.find(
        (option) => option.value === "Print Only"
      );
      const sizeInfo = filteredPrintSizes.find((option) => option.value === "A4");

      if (printData && variantDetails && formatInfo && sizeInfo) {
        setUserSelection({
          id: optionsData.id,
          generationJobId: printData.id,
          productId: variantDetails.products.id,
          format: formatInfo.id,
          formatTitle: formatInfo.value,
          size: sizeInfo.id,
          sizeTitle: sizeInfo.value,
          extras: null,
          extrasTitle: null,
          frame: null,
          frameTitle: null,
        });
      }
    } catch (error) {
      console.error("Error fetching print options: ", error);
      setError(true);
    }
  }, [printData, variantDetails]);

  useEffect(() => {
    fetchVariantDetails();
  }, [userSelection, fetchVariantDetails]);

  useEffect(() => {
    console.log(printData);
  }, [printData]);

  const handleFormatChange = useCallback(
    (item) => {
      setUserSelection((currentSelection) => {
        let newSelection = {
          ...currentSelection,
          format: item.id,
          formatTitle: item.value,
        };

        if (item.value === "Digital") {
          // Save the current size before switching to Digital
          setLastNonDigitalSize(currentSelection.size);

          // Reset size and extras for Digital
          newSelection.size = null;
          newSelection.extras = null;
          newSelection.sizeTitle = null;
        } else {
          // If switching back from Digital, restore the last non-digital size
          // Only update size if it's currently null (meaning we're coming back from Digital)
          if (currentSelection.size === null && lastNonDigitalSize !== null) {
            newSelection.size = lastNonDigitalSize;
            // You may need to set sizePrice based on the size, which might require additional logic
          }
        }

        return newSelection;
      });
    },
    [lastNonDigitalSize]
  );

  const handleSizeChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      const newSelection = {
        ...currentSelection,
        size: item.id,
        sizeTitle: item.value,
        sizePrice: item.base_price,
      };

      return newSelection;
    });
  }, []);

  const handleExtrasChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      const newSelection = {
        ...currentSelection,
        extras: item.id,
        extrasTitle: item.value,
        extrasPrice: item.base_price,
      };

      return newSelection;
    });
  }, []);

  const handleFrameChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      return { ...currentSelection, frame: item.id, frameTitle: item.value };
    });
  }, []);

  useEffect(() => {
    if (variantDetails) {
      setPrice(variantDetails.price);
    }
  }, [variantDetails]);

  const handleAddToBasket = async () => {
    setAddingToBasket(true);
    if (!variantDetails) {
      console.error("Variant details not found");
      return;
    }

    // Prepare the new item
    const newItem = {
      id: `${printData.id}/${variantDetails.id}`,
      variantId: variantDetails.id,
      generationJobId: printData.id,
      productId: variantDetails.products.id,
      sku: variantDetails.sku,
      price: variantDetails.price,
      title: `${printData.title} - ${variantDetails.products.friendly_name} - ${
        userSelection.formatTitle
      } ${userSelection.sizeTitle ? `- ${userSelection.sizeTitle}` : ""} ${
        userSelection.extrasTitle ? `- ${userSelection.extrasTitle}` : ""
      }`,
      image: printData.image_paths[0],
      quantity: 1,
    };

    try {
      await addToBasket(newItem);
    } catch (error) {
      console.error("There was an error adding the item to the basket.", error);
    } finally {
      setTimeout(() => setAddingToBasket(false), 1000);
    }
  };

  const handleNavigateToNewImage = (newSlug) => {
    toggleAmendOverlay();
    navigate(`/generated-print/${newSlug}`);
  };

  const toggleAmendOverlay = () => setShowAmendOverlay(!showAmendOverlay);

  const handleBackdropClick = (e) => {
    e.stopPropagation();
    setShowAmendOverlay(false);
  };

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        setShowAmendOverlay(false);
      }
    };

    if (showAmendOverlay) {
      window.addEventListener("keydown", handleEscKey);
      return () => window.removeEventListener("keydown", handleEscKey);
    }
  }, [showAmendOverlay]);

  useEffect(() => {
    fetchPrintData();
  }, [id, fetchPrintData]);

  useEffect(() => {
    fetchPrintOptions();
  }, [fetchPrintOptions]);

  const additionalInfo = [
    { label: "Recommended Room", key: "recommended_room" },
    { label: "Color Category", key: "color_category" },
    { label: "Impact Level", key: "color_psychology_impact_level" },
    { label: "Primary Trait", key: "color_psychology_primary_trait" },
    { label: "Dominant Color", key: "dominant_color", isColor: true },
    { label: "Vibrant Color", key: "vibrant_color", isColor: true },
  ];

  // Function to parse string RGB values to numbers
  const parseRGBValues = (colorArray) => {
    if (!Array.isArray(colorArray)) return [0, 0, 0];
    return colorArray.map((val) => Number(val));
  };

  // Update the handleColorClick to handle string RGB values
  const handleColorClick = (color) => {
    const rgbValues = parseRGBValues(color);
    const colorName = getColorName(rgbValues);
    navigate(
      `/collections/color/${encodeURIComponent(colorName.toLowerCase())}`
    );
  };

  // Update the arraysAreEqual helper function to handle string and number comparisons
  const arraysAreEqual = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    return (
      arr1.length === arr2.length &&
      arr1.every(
        (val, idx) => Math.round(Number(val)) === Math.round(Number(arr2[idx]))
      )
    );
  };

  // Helper function to convert array values to numbers
  const normalizeColorArray = (arr) => {
    if (!Array.isArray(arr)) return [0, 0, 0];
    return arr.map((val) => Number(val));
  };

  const parseColorArray = (colorString) => {
    try {
      return JSON.parse(colorString).map(Number);
    } catch (e) {
      console.error("Error parsing color:", e);
      return [0, 0, 0]; // fallback to black
    }
  };

  // Helper function to safely handle room data
  const parseRoomData = (roomData) => {
    if (Array.isArray(roomData)) {
      return roomData;
    }
    try {
      return JSON.parse(roomData);
    } catch (e) {
      console.error("Error parsing room data:", e);
      return [];
    }
  };

  return (
    <div>
      {ownedByUser && (
        <div className="fixed bottom-6 left-6 z-10">
          <Button onClick={toggleAmendOverlay}>Amend</Button>
        </div>
      )}
      <div className="mx-auto max-w-2xl px-4 lg:py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {printData ? (
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-14">
            {/* Left side - Image gallery OR AR viewer */}
            <div className="relative">
              {showAR ? (
                <div className="relative">
                  <button
                    onClick={() => setShowAR(false)}
                    className="absolute top-4 right-4 z-10 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                  <div className="w-full">
                    <ARImageViewer imageUrl={printData.image_paths[0]} />
                  </div>
                </div>
              ) : (
                <>
                  <Tab.Group as="div" className="flex flex-col-reverse">
                    {/* Image selector */}
                    <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                      <Tab.List className="grid grid-cols-4 gap-6">
                        {printData.image_paths.map((image, index) => (
                          <Tab
                            key={index}
                            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                          >
                            {({ selected }) => (
                              <>
                                <span className="sr-only"></span>
                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                  <img
                                    src={image}
                                    alt=""
                                    className="h-full w-full object-cover object-center"
                                  />
                                </span>
                                <span
                                  className={classNames(
                                    selected
                                      ? "ring-indigo-500"
                                      : "ring-transparent",
                                    "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>

                    <Tab.Panels className=" w-full">
                      {printData.image_paths.map((image, index) => (
                        <Tab.Panel key={index}>
                          <img
                            src={image}
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                          />
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                  <Button
                    onClick={() => setShowAR(true)}
                    className="w-full mt-6 bg-white !text-slate-900"
                    variant="secondary"
                  >
                    View in 3D
                  </Button>
                </>
              )}
            </div>

            {/* Right side - Product info */}
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                {printData.title ? printData.title : "Your Generated Print"}
              </h1>

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
              </div>

              <div className="my-6">
                <h3 className="font-bold mb-2">Description</h3>
                <p className="space-y-6 text-lg text-gray-700">
                  {printData.description || printData.revised_prompt}
                </p>
              </div>

              <div>
                <Cards
                  data={printFormats}
                  label="Format"
                  onChange={handleFormatChange}
                  defaultSelection={userSelection.format}
                />
              </div>

              {userSelection.format !== 1 && (
                <div className="mt-6">
                  <Cards
                    data={printSizes}
                    label="Size"
                    onChange={handleSizeChange}
                    defaultSelection={userSelection.size}
                  />
                </div>
              )}

              {userSelection.format === 3 && (
                <div className="mt-6">
                  <Cards
                    data={printFrames}
                    label="Frame"
                    onChange={handleFrameChange}
                    defaultSelection={userSelection.frame}
                  />
                </div>
              )}

              {userSelection.format === 3 && (
                <div className="mt-6">
                  <Cards
                    data={printExtras}
                    label="Extras"
                    onChange={handleExtrasChange}
                    defaultSelection={userSelection.extras}
                  />
                </div>
              )}

              <div className="my-10 flex">
                <Button
                  onClick={handleAddToBasket}
                  className="text-xl font-medium pl-12 pr-12 w-full md:w-auto"
                  disabled={addingToBasket}
                  loading={addingToBasket}
                >
                  {addingToBasket ? "Adding to bag" : "Add to bag"}
                </Button>
                <div className="flex items-center">
                  <p className="ml-4 text-3xl tracking-tight text-gray-900">
                    {formatCurrency(price)}
                  </p>
                </div>
              </div>

              <div className="my-6">
                <h3 className="font-bold mb-2 text-lg">Original Prompt</h3>
                <p className="space-y-6 text-sm text-gray-700">
                  {printData.original_prompt}
                </p>
              </div>

              <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">
                  Additional Information
                </h3>
                <p className="mb-4 text-sm text-gray-700">
                  Every piece of artwork that is created on Create Prints is
                  analysed using our own algorithm to determine how it will
                  impact the space you want to display it in.{" "}
                </p>

                {/* Add Color Palette Section */}
                {printData?.color_palette && (
                  <div className="mb-6">
                    <span className="font-medium block mb-3">
                      Color Palette:
                    </span>
                    <div className="flex flex-wrap gap-4">
                      {printData.color_palette.map((color, index) => {
                        // Normalize all color values to numbers
                        const rgbValues = normalizeColorArray(color);
                        const dominantColor = normalizeColorArray(
                          printData.dominant_color
                        );
                        const vibrantColor = normalizeColorArray(
                          printData.vibrant_color
                        );

                        const hexColor = rgbToHex(...rgbValues);
                        const colorName = getColorName(rgbValues);

                        // Simple array comparison
                        const isDominant = rgbValues.every(
                          (val, i) => val === dominantColor[i]
                        );
                        const isVibrant = rgbValues.every(
                          (val, i) => val === vibrantColor[i]
                        );

                        return (
                          <button
                            key={index}
                            onClick={() => handleColorClick(color)}
                            className="flex flex-col items-center group relative"
                          >
                            <div
                              className={`w-12 h-12 rounded-full border-2 transition-transform group-hover:scale-110 ${
                                isDominant || isVibrant
                                  ? "border-blue-500 ring-2 ring-blue-300"
                                  : "border-gray-200"
                              }`}
                              style={{
                                backgroundColor: hexColor,
                                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              }}
                            />
                            <span className="mt-2 text-sm text-gray-600 group-hover:text-blue-600">
                              {colorName}
                              {isDominant && " (Dominant)"}
                              {isVibrant && " (Vibrant)"}
                            </span>
                            <span className="mt-1 text-xs text-gray-400">
                              {hexColor.toUpperCase()}
                            </span>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className="space-y-2">
                  {printData.keywords && printData.keywords.length > 0 && (
                    <div className="flex flex-col sm:flex-row sm:items-center">
                      <span className="font-medium mr-2">Keywords:</span>
                      <div className="flex flex-wrap gap-2">
                        {printData.keywords.map((keyword, index) => (
                          <Link
                            key={index}
                            to={`/collections/keywords/${encodeURIComponent(
                              keyword.toLowerCase().replace(/\s+/g, "-")
                            )}`}
                            className="text-blue-600 hover:underline"
                          >
                            {keyword}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                  {additionalInfo.map(({ label, key, isColor }) => (
                    <div
                      key={key}
                      className={classNames(
                        key === "recommended_room"
                          ? "flex flex-col gap-3"
                          : "flex flex-col sm:flex-row sm:items-center"
                      )}
                    >
                      <span
                        className={classNames(
                          "font-medium",
                          key !== "recommended_room" && "mr-2"
                        )}
                      >
                        {label}
                      </span>
                      <div
                        className={classNames(
                          "flex flex-wrap",
                          key === "recommended_room" ? "gap-4 mb-4" : "gap-2"
                        )}
                      >
                        {printData[key] &&
                          (isColor ? (
                            <button
                              onClick={() => handleColorClick(printData[key])}
                              className="flex items-center hover:text-blue-600"
                            >
                              <div
                                className="w-4 h-4 rounded-full mr-2"
                                style={{
                                  backgroundColor: rgbToHex(
                                    // Parse the JSON string array
                                    ...parseColorArray(printData[key])
                                  ),
                                  border: "1px solid rgba(0,0,0,0.1)",
                                }}
                              />
                              {getColorName(
                                parseColorArray(printData[key]).map(Number)
                              )}
                            </button>
                          ) : key === "recommended_room" ? (
                            parseRoomData(printData[key]).map((room, index) => {
                              const IconComponent = roomIcons[room];
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col items-center gap-2 group"
                                >
                                  <div className="w-12 h-12 bg-white rounded-full border-2 transition-transform group-hover:scale-110 border-gray-200 flex items-center justify-center">
                                    {IconComponent && (
                                      <IconComponent
                                        className="h-6 w-6"
                                        strokeWidth={1.5}
                                      />
                                    )}
                                  </div>
                                  <Link
                                    to={`/collections/${key.replace(
                                      /_/g,
                                      "-"
                                    )}/${encodeURIComponent(
                                      room.toLowerCase().replace(/\s+/g, "-")
                                    )}`}
                                    className="flex items-center gap-2 text-blue-600"
                                  >
                                    <span className="text-sm text-gray-600 hover:text-blue-600">
                                      {room}
                                    </span>
                                  </Link>
                                </div>
                              );
                            })
                          ) : typeof printData[key] === "string" &&
                            printData[key] !== "Undefined" ? (
                            <Link
                              to={`/collections/${key.replace(
                                /_/g,
                                "-"
                              )}/${encodeURIComponent(
                                printData[key]
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")
                              )}`}
                              className="text-blue-600 hover:underline"
                            >
                              {printData[key]}
                            </Link>
                          ) : (
                            <span className="text-gray-400">Unknown</span>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading</div>
        )}
      </div>
    </div>
  );
};

// Update the rgbToHex function to handle string values
const rgbToHex = (r, g, b) => {
  const toHex = (c) => {
    const hex = parseInt(c).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export default GeneratedPrint;
