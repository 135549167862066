import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import PrivateRoute from "./PrivateRoute";
import Login from "./login";
import Home from "./home";
import "./App.css";
import GeneratedPrint from "./GeneratedPrint";
import { BasketProvider } from "./basketContext";
import OrderConfirmation from "./pages/orderConfirmation/OrderConfirmation";
import Header from "./components/Header/Header";
import Account from "./pages/Account/Account";
import Prints from "./pages/Prints/Prints";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer/Footer";
import Collections from "./pages/Collections/Collections";
import GeneratePrintV2 from "./pages/GeneratePrintV2/GeneratePrintV2";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <AuthProvider>
          <BasketProvider>
            <Header />
            <Routes>
              <Route exact path="/login" Component={Login} />
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/generate-print"
                element={
                  <PrivateRoute>
                    <GeneratePrintV2 />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/print/:slug/:id"
                element={<GeneratedPrint />}
              />            
              <Route
                exact
                path="/order-confirmation"
                element={<OrderConfirmation />}
              />
              <Route
                exact
                path="/account"
                element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                }
              />
              <Route exact path="/prints" element={<Prints />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/collections/*" element={<Collections />} />
              <Route path="/collections/:key/:value" element={<Collections />} />
            </Routes>
          </BasketProvider>
        </AuthProvider>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
