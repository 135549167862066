import { postData, postToApi } from "./postData";


const postGenerationJob = async (data, userSelection, userId, accessToken) => {
  console.log(data);
  try {
    const response = await postData("generation_jobs", data);

    if (response.error) {
      throw new Error(`Error posting data: ${response.error}`);
    }

    const generateImagePayload = {
      prompt: data.original_prompt,
      size: userSelection.orientation.value,
      style: userSelection?.style?.value,
      keywords: userSelection?.keywords,
      colors: userSelection?.colors,
      user: userId,   
      accessToken,
      forceOriginalPrompt: process.env.OPENAI_FORCE_ORIGINAL_PROMPT,
      job_id: response.data[0].id
    };

    const generateImage = await postToApi(
      `${process.env.REACT_APP_API_URL}/generate-image`,
      generateImagePayload
    );

    if (generateImage.error) {
      throw new Error(`Failed to post data: ${generateImage.error}`);
    }

    return {
      data: {
        jobDetails: response.data[0],
        imageData: generateImage.data.response,
      },
      error: null,
    };
  } catch (error) {
    console.error("Error in postGenerationJob:", error);
    return {
      data: null,
      error: error.message,
    };
  }
};

export default postGenerationJob;
