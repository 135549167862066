import React from "react";
import { useParams } from "react-router-dom";
import ArtGallery from "../../components/ArtGallery/ArtGallery";
import PageHeader from "../../components/PageHeader/PageHeader";

const Collections = () => {
  const { key, value } = useParams();
  const formattedKey = key.replace(/-/g, "_");

  // Function to capitalize the first letter of each word
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Function to format recommended room value
  const formatRecommendedRoom = (str) => {
    return capitalizeWords(str.replace(/-/g, ' '));
  };

  const filterMapping = {
    keywords: "keywords",
    color: "color_palette",
    color_category: "color_category",
    color_psychology_impact_level: "color_psychology_impact_level",
    color_psychology_primary_trait: "color_psychology_primary_trait",
    recommended_room: "recommended_room",
  };

  // Function to properly format keyword for PostgreSQL
  const formatKeyword = (value) => {
    // Convert to lowercase for case-insensitive matching
    // and properly escape any quotes
    const formattedValue = decodeURIComponent(value)
      .toLowerCase()
      .replace(/"/g, '\\"');
    return `{${formattedValue}}`; // PostgreSQL array format
  };

  const filters = filterMapping[formattedKey] 
    ? { 
        [filterMapping[formattedKey]]: formattedKey === 'keywords'
          ? formatKeyword(value)
          : formattedKey === 'color'
            ? { type: 'color_match', value: decodeURIComponent(value) }
          : formattedKey === 'recommended_room'
            ? formatRecommendedRoom(value)
            : capitalizeWords(decodeURIComponent(value))
      }
    : {};

  // For debugging
  console.log('Filters:', filters);

  const generateTitle = () => {
    const decodedValue = decodeURIComponent(value);
    switch (formattedKey) {
      case "keywords":
        return `Prints with keyword: ${capitalizeWords(decodedValue)}`;
      case "color_category":
        return `Prints in ${capitalizeWords(decodedValue)}`;
      case "color_psychology_impact_level":
        return `Prints with ${capitalizeWords(decodedValue)} impact`;
      case "color_psychology_primary_trait":
        return `Prints evoking ${capitalizeWords(decodedValue)}`;
      case "recommended_room":
        return `Prints for your ${formatRecommendedRoom(decodedValue)}`;
      case "color":
        return `Prints with ${capitalizeWords(decodedValue)} tones`;
      default:
        return "Collection";
    }
  };

  return (
    <div className="container mx-auto p-4 sm:p-8 max-w-7xl">
      <PageHeader title={generateTitle()} className="text-3xl sm:text-5xl md:text-7xl mb-8" />
      <ArtGallery 
        className="columns-1 sm:columns-2 md:columns-3 lg:columns-4 xl:columns-5" 
        resultsPerPage={20} 
        filters={filters} 
      />
    </div>
  );
};

export default Collections;
