import { useEffect, useState, useMemo } from "react";
import { fetchData } from "../../utility/fetchData";
import Button from "../Button.js";
import { motion } from "framer-motion";
import { getColorName } from '../../utility/colorNaming';

const ArtGallery = (props) => {
  const { className, userId, resultsPerPage = 10, filters = {}, featured = false } = props;
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const classNames = `columns-1 md:columns-3 gap-4 ${className ? className : ""}`;

  // Memoize the filters object to prevent unnecessary re-renders
  const memoizedFilters = useMemo(() => filters, [JSON.stringify(filters)]);

  useEffect(() => {
    const loadImages = async () => {
      if (loading) return; // Check loading status to prevent duplicate calls
      setLoading(true);

      try {
        const startIndex = (currentPage - 1) * resultsPerPage;
        const endIndex = startIndex + resultsPerPage - 1;

        let queryFilters = { image_uploaded: true };
        if (userId !== undefined) {
          queryFilters.user_id = userId;
        }
        if (featured) {
          queryFilters.featured = featured;
        }

        // Handle color filter specially
        Object.entries(memoizedFilters).forEach(([key, value]) => {
          if (value != null) {
            if (key === 'color_palette' && value.type === 'color_match') {
              // Add special color matching filter
              queryFilters.color_match = value.value;
            } else {
              queryFilters[key] = value;
            }
          }
        });

        // Modify the fetchData call to include color matching
        const response = await fetchData(
          "generation_jobs",
          `id, image_paths, title, color_palette`,
          queryFilters,
          { start: startIndex, end: endIndex },
          false,
          // Add color matching logic
          queryFilters.color_match ? {
            matchType: 'color',
            value: queryFilters.color_match
          } : null
        );

        if (response && response.length > 0) {
          // Filter results to only include images that have the matching color
          const filterResponse = response.filter(item => {
            if (!queryFilters.color_match) return true;
            
            // Check if any color in the palette matches the requested color
            return item.color_palette?.some(color => {
              const colorName = getColorName(color.map(Number));
              return colorName.toLowerCase() === queryFilters.color_match.toLowerCase();
            });
          });

          setImages((prevImages) => [...prevImages, ...filterResponse]);
          setHasMore(response.length === resultsPerPage);
          setError(false);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        setError(true);
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    loadImages();
  }, [currentPage, userId, resultsPerPage, memoizedFilters, featured]);

  const handleLoadMore = () => {
    if (!loading) setCurrentPage((prevPage) => prevPage + 1);
  };

  const generateSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  const generatedImageItems = images.map((item, index) => (
    <div key={index} className="p-2 mb-4 bg-white rounded-xl hover:shadow-lg">
      <a href={`/print/${generateSlug(item.title)}/${item.id}`}>
        <motion.div
          className="mb-4 relative m-h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.img
            whileHover={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="rounded-md inset-0 w-full h-full object-cover"
            src={item.image_paths[0]}
            alt={item.title}
          />
          {item.image_paths[1] && (
            <motion.img
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="rounded-md absolute inset-0 w-full h-full object-cover"
              src={item.image_paths[1]}
              alt={item.title}
              style={{ opacity: 0 }}
            />
          )}
        </motion.div>
        {item.title && (
          <p className="overflow-hidden text-ellipsis p-4 pt-0 text-slate-900 text-lg">
            {item.title}
          </p>
        )}
      </a>
    </div>
  ));

  return (
    <>
      {error && <div>There was an error loading the images.</div>}
      <div className={classNames}>{generatedImageItems}</div>
      {hasMore && !loading && (
        <div className="my-8 mx-auto">
          <Button onClick={handleLoadMore}>Load more</Button>
        </div>
      )}
    </>
  );
};

export default ArtGallery;
